import * as React from "react";
import 'bootstrap/dist/css/bootstrap.css'
import Logo from '../../images/lifeguide-logo.png';
import FooterLogo from '../../images/logo-footer.png';
import "@fontsource/inter"
import "../../styles/main.scss"
import markerIcon from '../../images/marker.png'
import phoneIcon from '../../images/phone.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Mailchimp from './mailchimp'
import Navbar from 'react-bootstrap/Navbar'
import twitter from '../../images/twt.svg'
import fb from '../../images/fb.svg'
import ig from '../../images/ig.svg'
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { useEffect } from "react";
import { Link } from 'gatsby';
import parse from 'html-react-parser';

// styles
const pageStyles = {
  color: "#232129",
}

const logoStyle = {
  maxWidth: '143px'
}

const redirectHome = () => {
  window.location.href = `${process.env.HOME_URL_PATH}`
}

const redirectLogin = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/users/sign_in`
}

const redirectSignup = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/users/sign_up`
}

const redirectSubscribe = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/subscription`
}

const redirectSocialMedia = (sc) => {
  window.location.href = parse(sc)[1].props.children.props.href
}

const footerContent = graphql`
  query FOOTER1 {
    wpCategory(name: {eq: "Footer"}) {
      id
      posts {
        nodes {
          content
        }
      }
    }
  }
`

// markup
const ResourcePage = ({ location }) => {
  useEffect(() => {
    const imgEl = document.getElementsByClassName('lazyload');
    for (var i=0; i<imgEl.length; i++) {
      if(imgEl[i].getAttribute('data-src')) {
        imgEl[i].removeAttribute('src'); //use only if you need to remove data-src attribute after setting src
        imgEl[i].setAttribute('src',imgEl[i].getAttribute('data-src'));
        imgEl[i].removeAttribute('data-src'); //use only if you need to remove data-src attribute after setting src
      }
    }
  });

  return (
    <main style={pageStyles}>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Lifeguide Consultancy</title>
        </Helmet>
      </div>
      <section className="container-fluid">
        <div className="row">
          <div className="col-12 p-0">
            <section className="first-section">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <Navbar collapseOnSelect expand="lg">
                      <Navbar.Brand href="#home" onClick={() => redirectHome()}>
                        <img src={Logo} style={logoStyle} className='w-100' />
                      </Navbar.Brand>
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                      <Navbar.Collapse id="responsive-navbar-nav">
                        <ul className="navbar-nav mx-auto">
                          <li className="nav-item">
                            <a className="nav-link" href="/">Home</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/#about-us">About Us</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#" onClick={() => redirectSubscribe()}>Subscribe</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/#how-it-works">How it works</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/#faq">FAQ</a>
                          </li>
                        </ul>
                        <div className="form-inline my-2 my-lg-0">
                          <button className="btn btn-ghost mr-3" onClick={() => redirectLogin()}>Sign in</button>
                          <button className="btn btn-signup my-2 my-sm-0" onClick={() => redirectSignup()}>Sign up</button>
                        </div>
                      </Navbar.Collapse>
                    </Navbar>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h1 className="text-center custom-margin-bottom">{ location.state ? location.state.title : "Feng Shui for everybody"}</h1>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-content">
              {/* <nav className="breadcrumb-container" aria-label="breadcrumb">
                <div className="container">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><img src={homeIcon} /></a></li>
                    <li className="breadcrumb-item active" aria-current="page">Feng Shui for everybody</li>
                  </ol>
                </div>
              </nav> */}
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <section className="article-content">
                      {/* <p className="text-gray">Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend egestas fringilla sapien.</p>
                      <p className="text-gray">Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. <a href="#">Mattis mauris semper</a> sed amet vitae sed turpis id.</p>
                      <ul>
                        <li>Quis elit egestas venenatis mattis dignissim.</li>
                        <li>Cras cras lobortis vitae vivamus ultricies facilisis tempus.</li>
                        <li>Orci in sit morbi dignissim metus diam arcu pretium.</li>
                      </ul>
                      <p className="text-gray">Quis semper vulputate aliquam venenatis egestas sagittis quisque orci. Donec commodo sit viverra aliquam porttitor ultrices gravida eu. Tincidunt leo, elementum mattis elementum ut nisl, justo, amet, mattis. Nunc purus, diam commodo tincidunt turpis. Amet, duis sed elit interdum dignissim.</p>
                      <h2>From beginner to expert in 30 days</h2>
                      <p className="text-gray">Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis arcu ipsum urna nibh. Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed tellus mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed nullam sed diam turpis ipsum eu a sed convallis diam.</p>
                      <img src={dummyArticle} />
                      <span className="text-gray">Sagittis scelerisque nulla cursus in enim consectetur quam.</span>
                      <h2>Everything you need to get up and running</h2>
                      <p className="text-gray">Purus morbi dignissim senectus mattis adipiscing. Amet, massa quam varius orci dapibus volutpat cras. In amet eu ridiculus leo sodales cursus tristique. Tincidunt sed tempus ut viverra ridiculus non molestie. Gravida quis fringilla amet eget dui tempor dignissim. Facilisis auctor venenatis varius nunc, congue erat ac. Cras fermentum convallis quam.</p>
                      <p className="text-gray">Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit.</p> */}
                      <div className="mt-3" dangerouslySetInnerHTML={{ __html: location.state ? location.state.content : "Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend egestas fringilla sapien." }} />
                    </section>
                  </div>
                </div>
              </div>
            </section>
            <footer className="page-footer pt-0">
              <div className="bg-footer-dark">
                <StaticQuery
                  query={footerContent}
                  render={(data) => {
                    if (data.wpCategory.posts.nodes) {
                      const footer = data.wpCategory.posts
                      return (
                        <div className="container">
                          <div className="row p-0">
                            <div className="col-12 col-lg-4 mb-4">
                              <h6 className="text-uppercase font-weight-bold mb-3">
                                <img src={FooterLogo} style={logoStyle} className='w-100' />
                              </h6>
                              <div className="row">
                                <div className="col-2">
                                  <img src={phoneIcon} alt="phone" />
                                </div>
                                <div className="col-10">
                                  <p className="text-gray">
                                    <div dangerouslySetInnerHTML={{ __html: footer.nodes[3].content }} />
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-2">
                                  <img src={markerIcon} alt="marker" />
                                </div>
                                <div className="col-10">
                                  <p className="text-gray">
                                    <div dangerouslySetInnerHTML={{ __html: footer.nodes[4].content }} />
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-lg-2 mb-4">
                              <h6 className="text-uppercase font-weight-bold">Company</h6>
                              <p>
                                <a href="/#head">Home</a>
                              </p>
                              <p>
                                <a href="/#about-us">About Us</a>
                              </p>
                              <p>
                                <a href="" onClick={() => redirectSubscribe()}>Subscribe</a>
                              </p>
                              <p>
                                <Link to="/resource">Resources</Link>
                              </p>
                              <p>
                                <Link to="/privacyPolicy">Privacy Policy</Link>
                              </p>
                            </div>
                            <div className="col-6 col-lg-2 mb-4">
                              <h6 className="text-uppercase font-weight-bold">Help</h6>
                              <p>
                                <a href="/#how-it-works">How it works</a>
                              </p>
                              <p>
                                <a href="/#faq">FAQ</a>
                              </p>
                            </div>
                            <div className="col-12 col-lg-4 mb-4">
                              <h6 className="text-uppercase font-weight-bold">Subscribe to our newsletter</h6>
                              <p className="text-white">The latest news, articles, and resources, sent to your inbox weekly.</p>
                              <Mailchimp
                                action='https://tech.us14.list-manage.com/subscribe/post?u=cbcd424e4f4e518d73aaac46f&amp;id=3885beb50c'
                                fields={[
                                  {
                                    name: 'EMAIL',
                                    placeholder: 'Email Address',
                                    type: 'email',
                                    required: true
                                  }
                                ]}
                                />
                            </div>
                          </div>

                          <hr className="bg-gray" />
                          <div className="row pt-2 pb-4">
                            <div className="col-6">
                              <p className="text-gray">&copy; 2021 Lifeguideconsultancy. All rights reserved.</p>
                            </div>
                            <div className="col-6 text-right footer-sns">
                            < img src={fb} onClick={() => redirectSocialMedia(footer.nodes[2].content)}/>
                              <img src={ig} onClick={() => redirectSocialMedia(footer.nodes[1].content)}/>
                              <img src={twitter} onClick={() => redirectSocialMedia(footer.nodes[0].content)}/>
                            </div>
                          </div>
                        </div>
                        )
                      }
                    }}
                  />
              </div>
            </footer>
          </div>
        </div>
      </section>
    </main>
  )
}

export default ResourcePage
            